<template>
  <v-card>
    <v-card-title
      class="justify-center"
      v-html="title[userType] || '¡Te damos la bienvenida!'"
      >
    </v-card-title>

    <v-img
      v-if="tab == 0"
      :max-height="$vuetify.breakpoint.mobile ? 250 : 350"
      contain
      :src="require('@/assets/onboarding/first.svg')"
      ></v-img>

    <v-card-text
      class="mt-2"
      >
      <user-type
        v-if="tab == 0"
        @next="setUserType"
        ></user-type>
      <Store
        v-if="tab == 1 && userType == 'partner'"
        @next="next"
        ></Store>
      <complete
        v-if="tab == 2"
        :userType="userType"
        @finish="finish"
        ></complete>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

import { SignIn, UpdateFirstSignIn } from '@/graphql/mutations/users'
import UserType from './UserType'

const Complete = () => import('./Complete')
const Store = () => import('./Store')

export default {
  data () {
    return {
      tab: 0,
      userType: null,
      title: {
        partner: '¡Ya eres <span class="ml-1 secondary--text font-weight-bold">PARTNER!</span>',
        fan: '¡Ya eres <span class="ml-1 primary--text font-weight-bold">FAN</span>!'
      }
    }
  },

  created () {

    Vue.use(VueGoogleMaps, {
      load: {
        key: "AIzaSyB3RTiKtL9Wx4PT1gwp9FsRGsDu9qJittc",
        libraries: "places", // necessary for places input
        region: process.env.VUE_APP_LOCATION,
        language: "es"
      }
    })
  },

  components: {
    UserType, Complete, Store
  },

  methods: {
    finish () {
      this.$apollo.mutate({
        mutation: UpdateFirstSignIn,
        variables: {
          input: {
          }
        }
      }).then( res => {
        this.$apollo.mutate({
          mutation: SignIn,
          variables: {
            input: {
              token: localStorage.getItem('auth_token')
            }
          },
          fetchPolicy: 'no-cache'
        }).then(res => {
          let data = res.data.signIn

          if (data.token) {
            localStorage.setItem('auth_token', data.token)
            localStorage.setItem('prefetched_sub', data.user.selectedProfile.id)

            this.$store.commit("setCurrentUser", data.user) 
          if (this.userType == 'partner') {
            this.$router.push({path:'/admin'})
            this.$emit('reload')
          } else {
            this.$router.go()
          }
          } else {
            localStorage.removeItem('auth_token')
          }
        }).catch(errors => {
          localStorage.removeItem('auth_token')
          this.$router.go()
        }) 
      })
    },

    setUserType (userType) {
      this.userType = userType
      this.next()
    },

    next () {
      if (this.userType == 'partner') {
        this.tab += 1
      } else {
        this.tab += 2
      }
    }
  }
}
</script>
