<template>
  <div>
    <div class="body-1 text-center">
      <span class="font-weight-medium">PartnersFans</span> es la primera red
      social del mundo del ecommerce.
    </div>
    <div class="d-md-flex mt-3">
      <v-btn
        :class="$vuetify.breakpoint.mdAndUp ? 'mr-1' : 'mb-1'"
        class="py-5"
        :style="$vuetify.breakpoint.mdAndUp ? 'width: 50%' : 'width: 100%'"
        outlined
        :block="$vuetify.breakpoint.mobile"
        @click="$emit('next', 'fan')"
      >
        <div class="d-flex align-center">
          <v-icon color="primary" class="mr-1">mdi-shopping</v-icon> Eres
          <span class="ml-1 font-weight-bold primary--text">Fan</span> (compro)
        </div>
        <div class="mt-2">
          Si compras por Internet y te gusta vitrinear, eres Fan
        </div>
      </v-btn>

      <v-btn
        :class="$vuetify.breakpoint.mdAndUp ? 'ml-1' : 'mt-1'"
        :style="$vuetify.breakpoint.mdAndUp ? 'width: 50%' : 'width: 100%'"
        class="py-5"
        outlined
        :block="$vuetify.breakpoint.mobile"
        @click="$emit('next', 'partner')"
      >
        <div class="d-flex align-center">
          <v-icon color="secondary" class="mr-1">mdi-storefront</v-icon> Eres
          <span class="ml-1 font-weight-bold secondary--text">Partner</span>
          (vendo)
        </div>

        <div class="mt-2">
          Si eres dueño de un emprendimiento, eres un Partner.
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    next(userType) {
      this.$emit("next", userType);
    }
  }
};
</script>

<style scoped>
>>> .v-btn__content {
  flex-direction: column !important;
  height: fit-content;
  width: 100%;
}

>>> .v-btn {
  height: fit-content !important;
  white-space: normal;
}
</style>
